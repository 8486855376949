import React, { useState, useCallback } from "react"
import styled from "styled-components"
import Form, { FormActions } from "@src/components/form"
import FormFieldInputTextarea from "@src/components/form-field-input-textarea"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputEmail from "@src/components/form-field-input-email"
import Button from "@src/components/core-button"

const StyledFormActions = styled(FormActions)`
  @media only screen and (${props => props.theme.screen.small.min}) {
    align-items: flex-start;
  }
`

const StyledForm = styled(Form)`
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .narrow {
      width: 49%;
    }
    & > .full-width {
      width: 100%;
    }
    & > .left,
    & > ${StyledFormActions} {
      float: left;
    }
    & > .right {
      float: right;
    }
  }
`

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState(null)

  const handleSubmit = useCallback(() => {
    setFormStatus({
      processing: "Please wait a moment while we save your message...",
    })
  }, [setFormStatus])
  const handleSubmitError = useCallback(
    error => {
      setFormStatus({ error: error.message })
    },
    [setFormStatus]
  )
  const handleSubmitSuccess = useCallback(
    result => {
      if (result.netlify) {
        setFormStatus({
          success:
            "Thanks for the message! Our team will get back to you soon.",
        })
      } else {
        handleSubmitError({
          message: "There was an error saving the message.",
        })
      }
    },
    [setFormStatus, handleSubmitError]
  )
  return (
    <StyledForm
      name="contact-zoho"
      onSubmit={handleSubmit}
      onSubmitError={handleSubmitError}
      onSubmitSuccess={handleSubmitSuccess}
      netlify
    >
      <FormFieldInputTextarea
        name="message"
        placeholder="Message"
        validation={{
          required:
            "We're happy to answer any questions about our products and services",
        }}
        className="full-width left"
      />
      <FormFieldInputText
        name="first-name"
        placeholder="First name"
        validation={{ required: "First name is required" }}
        className="narrow left"
      />
      <FormFieldInputText
        name="last-name"
        placeholder="Last name"
        validation={{ required: "Last name is required" }}
        className="narrow right"
      />
      <FormFieldInputEmail
        name="email"
        placeholder="Email"
        validation={{
          required: "Let us know where we can email a reply!",
        }}
        className="full-width left"
      />
      <StyledFormActions
        submitting={formStatus && formStatus.processing ? true : undefined}
      >
        {(formStatus?.success && (
          <p className="form-success">{formStatus.success}</p>
        )) ||
          (formStatus?.error && (
            <p className="form-error">{formStatus.error}</p>
          )) ||
          (formStatus?.processing && (
            <p className="form-info">{formStatus.processing}</p>
          ))}
        <Button
          type="submit"
          disabled={formStatus && formStatus.processing ? true : undefined}
        >
          Send
        </Button>
      </StyledFormActions>
    </StyledForm>
  )
}
export default ContactForm
