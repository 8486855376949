import React from "react"
import styled from "styled-components"
import { useMediaPartners } from "@src/hooks/useMediaPartners"
import Image from "@src/components/core-image"
import ResponsiveSection from "@src/components/container-responsive-section"

const partnerWidth = { mobile: 100, medium: 120 }
const partnerSideMargin = 24

const StyledSection = styled(ResponsiveSection)`
  margin: 2em 0;
  h2 {
    font-family: "system-ui";
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  & .media-partners {
    overflow: hidden;
    position: relative;
    height: 100px;
    width: 100%;
    & .scroller {
      display: flex;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      & > div {
        display: flex;
        justify-content: center;
        width: ${partnerWidth.mobile}px;
        margin: 0 ${partnerSideMargin}px;
      }
      animation: slide 20s linear infinite alternate;
      &:hover {
        animation-play-state: paused;
      }
    }

    @keyframes slide {
      0% {
        left: 0;
      }
      100% {
        left: ${props =>
          props.$count
            ? `calc(-${
                props.$count * (partnerWidth.mobile + partnerSideMargin * 2)
              }px + 100%)`
            : "100%"};
      }
    }
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    h2 {
      font-size: 32px;
    }
    & .media-partners {
      & .scroller {
        & > div {
          width: ${partnerWidth.medium}px;
        }
      }
      @keyframes slide {
        0% {
          left: 0;
        }
        100% {
          left: ${props =>
            props.$count
              ? `calc(-${
                  props.$count * (partnerWidth.medium + partnerSideMargin * 2)
                }px + 100%)`
              : "100%"};
        }
      }
    }
  }
`

const SocialProofMediaPartners = () => {
  const mediaPartners = useMediaPartners()
  return (
    <StyledSection $count={mediaPartners.length}>
      <h2>As Seen On</h2>
      <div className="media-partners">
        <div className="scroller">
          {mediaPartners.map(mediaPartner => {
            const key = `media-partner-${mediaPartner.id}`
            if (mediaPartner.logoTile) {
              return (
                <Image
                  key={key}
                  title={mediaPartner.title}
                  {...mediaPartner.logoTile}
                />
              )
            }
            return <div key={key}>{mediaPartner.title}</div>
          })}
        </div>
      </div>
    </StyledSection>
  )
}
export default SocialProofMediaPartners
