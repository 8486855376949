import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import { ImagePropTypes } from "@src/components/core-image"
import HtmlContent from "@src/components/core-value-html"
import Button from "@src/components/core-button"
import SocialProofMediaPartners from "@src/components/content-social-proof-media-partners"
import ContactForm from "@src/components/form-contact"

const StyledIntroSection = styled(ResponsiveColumns)`
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > *:first-child {
      grid-column: 2 / 3;
      padding-right: 30px;
    }
    & > *:nth-child(2) {
      grid-column: 3 / 4;
    }
  }
`
const StyledContentActions = styled(ResponsiveSection)`
  & > div {
    text-align: center;
  }
`

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        content,
        images: { header: headerImage },
        seo: pageSeo,
      },
    },
  } = data
  const firstSection = content.indexOf("<h2")
  const contentIntro = content.substring(0, firstSection)
  const contentMain = content.substring(firstSection)
  GTM.dataLayerPushPageInfo({
    template: "static-media",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={generateMeta(pageUri, { ...pageSeo, metaRobotsNoindex: "index" })}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{title}</h1>
      </HeaderSimple>
      <StyledIntroSection variant="right">
        <HtmlContent html={contentIntro} />
        <ContactForm />
      </StyledIntroSection>
      <ResponsiveSection align="left">
        <HtmlContent html={contentMain} imageBlocks />
      </ResponsiveSection>
      <StyledContentActions contained>
        <Button href="/contact/" external>
          Contact Us
        </Button>
      </StyledContentActions>
      <SocialProofMediaPartners />
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
