import { useStaticQuery, graphql } from "gatsby"

export const useMediaPartners = () => {
  const {
    allWpMediaPartner: { nodes: mediaPartners },
  } = useStaticQuery(graphql`
    query MediaPartnersQuery {
      allWpMediaPartner {
        nodes {
          id: databaseId
          title
          sorting
          logoTile {
            ...WpImageSizesQuery
          }
        }
      }
    }
  `)
  mediaPartners.sort((a, b) => {
    if (a.sorting && (!b.sorting || a.sorting < b.sorting)) {
      return -1
    } else if (b.sorting && (!a.sorting || b.sorting < a.sorting)) {
      return 1
    }
    return 0
  })
  return mediaPartners
}
